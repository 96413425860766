
import { Component, Vue } from "vue-property-decorator";
import DateTimeMixin from "@/mixins/datetime";
import {
  ButtonBackToDashboard,
  FieldPTable,
  PaginationPTableType,
  PButton,
  PPersonalizeTable,
  PSidebar,
  PTab,
  PTable,
  PTabs,
} from "@/common/components";
import { Getter } from "vuex-class";
import { ExportRequestsGrid } from "./ExportRequestsGrid.vue";
import { ExportRequestItem, ExportRequestsFilters } from "@/pages/export-requests/exportRequestsPage.type";
import { ExportRequestsParams, OrderByFields } from "@/services";
import FilterExportRequestsPage from "@/pages/export-requests/FilterExportRequestsPage.vue";
import { ExportRequestStatus } from "@/entities";
import { Instant, LocalDateTime } from "@js-joda/core";
import { Dictionary } from "vue-router/types/router";

@Component({
  name: "export-requests-page",
  mixins: [DateTimeMixin],
  components: {
    FilterExportRequestsPage,
    PTable,
    PTabs,
    PTab,
    PSidebar,
    PPersonalizeTable,
    PButton,
    ButtonBackToDashboard,
    ExportRequestsGrid,
  },
})
export default class ExportRequestsPage extends Vue {
  @Getter("getReportsFields") getReportsFields!: FieldPTable[];
  showFilter = true;
  pageSize = 10;

  pagination: PaginationPTableType = {
    pageSize: this.pageSize,
    totalElements: 0,
    currentPage: 1,
  };

  sort: { sortDesc: boolean; sortField: string } = {
    sortDesc: true,
    sortField: "requestedAt",
  };

  exportRequestItems: ExportRequestItem[] = [];

  fields: FieldPTable[] = [];

  queryParams = this.$route.query;

  filters: ExportRequestsFilters = {
    filename: this.queryParams.filename?.toString() ?? "",
  };

  created(): void {
    this.setDatesByDefault();

    this.filters.status = this.queryParams.status
      ?.toString()
      .split(",")
      .filter((s) => s !== "") ?? [
      ExportRequestStatus.PENDING,
      ExportRequestStatus.PROCESSING,
      ExportRequestStatus.PROCESSED,
      ExportRequestStatus.ERROR,
    ];
  }

  setDatesByDefault(): void {
    const dateStartDefault = new Date();
    const dateEndDefault = new Date();

    dateStartDefault.setDate(dateStartDefault.getDate() - 7);
    dateStartDefault.setHours(0, 0, 0, 0);
    dateEndDefault.setHours(23, 59, 59, 999);

    this.filters.requestedAtStart = dateStartDefault;
    this.filters.requestedAtEnd = dateEndDefault;
  }

  async mounted(): Promise<void> {
    await this.searchExportRequests();
  }

  async onChangeFilters(): Promise<void> {
    this.pagination.currentPage = 1;
    await this.searchExportRequests();
  }

  async searchExportRequests(): Promise<void> {
    const limit = Number(this.pagination.pageSize);
    const offset = Number(this.pagination.currentPage - 1) * limit;
    const sortFields: OrderByFields[] = [
      {
        field: this.sort.sortField || "requestedAt",
        direction: this.sort.sortDesc ? "DESC" : "ASC",
      },
    ];

    const params: ExportRequestsParams = {
      filename: this.filters.filename || undefined,
      requestedAtStart: this.toLocalDateTime(this.filters.requestedAtStart),
      requestedAtEnd: this.toLocalDateTime(this.filters.requestedAtEnd),
      processedAtStart: this.toLocalDateTime(this.filters.processedAtStart),
      processedAtEnd: this.toLocalDateTime(this.filters.processedAtEnd),
      format: this.filters.format || undefined,
      status: this.filters.status,
    };
    this.$router
      .replace({
        path: this.$route.path,
        query: params as Dictionary<string>,
      })
      .catch(() => {
        // Do nothing
      });

    this.$services.exportRequest.fetchExportRequests(params, sortFields, limit, offset).then((response) => {
      if (response) {
        this.exportRequestItems = response.exportRequests;
        this.pagination.totalElements = response.totalResults;
      }
    });
  }

  toLocalDateTime(date?: Date): string | undefined {
    if (date) {
      return LocalDateTime.ofInstant(Instant.parse(date.toISOString())).toString();
    }
  }
}
