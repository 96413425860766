
import { Component, Prop, Vue } from "vue-property-decorator";
import VueFilters from "@/vue-filters";
import { FieldPTable, FieldPTableType, PaginationPTableType } from "@/common/components";
import { i18n } from "@/i18n";
import DateTimeMixin from "@/mixins/datetime";
import VueMethods from "@/vue-methods";
import PPagination from "@/common/components/look/PPagination/PPagination.vue";
import PButton from "@/common/components/look/PButton/PButton.vue";
import { ExportRequestItem, IField } from "@/pages/export-requests/exportRequestsPage.type";
import { ExportRequestStatus } from "@/entities";

@Component({
  name: "export-request-grid",
  computed: {
    ExportRequestStatus() {
      return ExportRequestStatus;
    },
  },
  components: { PPagination, PButton },
  mixins: [VueFilters, DateTimeMixin, VueMethods],
})
export class ExportRequestsGrid extends Vue {
  @Prop({ required: true }) items!: ExportRequestItem[];
  @Prop({ required: true }) fields!: FieldPTable[];
  @Prop({ required: true }) sort!: { sortDesc: boolean; sortField: string };
  @Prop({ required: true }) pagination!: PaginationPTableType;

  language = i18n.locale;

  downloadExport(item: ExportRequestItem): void {
    this.$services.exportRequest.downloadExport(item.id!).then((file: File) => {
      this.$emit("fileDownloaded");
      const aElement = document.createElement("a");
      const url = URL.createObjectURL(file);
      aElement.href = url;
      aElement.download = file.name;
      aElement.click();
      window.URL.revokeObjectURL(url);
    });
  }

  get _pagination(): PaginationPTableType {
    return { ...this.pagination };
  }

  set _pagination(value: PaginationPTableType) {
    this.$emit("update:pagination", value);
    this.$emit("change");
  }

  isHidden(fi: FieldPTable): boolean {
    return fi.type == FieldPTableType.HIDDEN;
  }
  isShowable(fi: FieldPTable): boolean {
    if (this.isHidden(fi)) return false;
    return fi.show;
  }

  get _fields(): IField[] {
    const fields: IField[] = [];
    this.fields.forEach((value) => {
      if (this.isShowable(value)) {
        fields.push({
          key: value.key,
          label: value.label ?? "",
          sortable: value.showOrderField,
          thClass: "table-header",
          tdClass: "table-cell",
          visible: true,
          aux: value.key == "actions",
          formatter: value.formatter,
        });
      }
    });
    return fields;
  }

  onSortingChanged(sort: { sortBy: string; sortDesc: boolean }): void {
    const newSort = { sortDesc: sort.sortDesc, sortField: sort.sortBy };

    this.$emit("update:sort", newSort);
    this.$emit("change");
  }
}

export default ExportRequestsGrid;
