
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  PRow,
  PFormSelectNarrow,
  PButton,
  PFormDatepickerActions,
  PFormInputHour,
  PFormSelect,
  ButtonSearch,
  PFormCheckBox,
  PSidebar,
  SelectType,
} from "@/common/components";
import {
  ExportRequestsFilters,
  FilterExportRequestsPageProps,
  PROCESSED_AT,
  REQUESTED_AT,
} from "@/pages/export-requests/exportRequestsPage.type";
import { i18n } from "@/i18n";
import DateTimeMixin from "@/mixins/datetime";
import { ExportRequestStatus } from "@/entities";
import FilterAdvancedSearchExportRequestsPage from "@/pages/export-requests/FilterAdvancedSearchExportRequestsPage.vue";

@Component({
  mixins: [DateTimeMixin],
  name: "filter-export-requests-page",
  computed: {
    ExportRequestStatus() {
      return ExportRequestStatus;
    },
  },
  components: {
    PFormSelect,
    PSidebar,
    PFormCheckBox,
    ButtonSearch,
    PFormInputHour,
    PButton,
    PFormDatepickerActions,
    PFormSelectNarrow,
    PRow,
    FilterAdvancedSearchExportRequestsPage,
  },
})
export default class FilterExportRequestsPage extends Vue {
  @Prop({ required: true }) value!: ExportRequestsFilters;
  options = [
    { text: this.$t("exportRequest.filter.searchByRequestedAt"), value: "requestedAt" },
    { text: this.$t("exportRequest.filter.searchByProcessedAt"), value: "processedAt" },
  ];
  valueLocal: FilterExportRequestsPageProps = {
    searchBy: this.options[0].value,
    dateStart: new Date(),
    dateEnd: new Date(),
    filename: "",
    format: "",
    status: [] as string[],
  };
  showModalAdvancedSearch = false;
  isActiveButtonAdvancedSearch = false;

  @Watch("value", { deep: true, immediate: true })
  onChangeValue(): void {
    this.valueLocal.searchBy = this.value.requestedAtStart ? REQUESTED_AT : PROCESSED_AT;
    this.valueLocal.dateStart = this.value.requestedAtStart || this.value.processedAtStart || new Date();
    this.valueLocal.dateEnd = this.value.requestedAtEnd || this.value.processedAtEnd || new Date();
    this.valueLocal.format = this.value.format || "";
    this.valueLocal.filename = this.value.filename || "";

    this.valueLocal.status[0] = this.value.status?.includes(ExportRequestStatus.PENDING)
      ? ExportRequestStatus.PENDING
      : false;
    this.valueLocal.status[1] = this.value.status?.includes(ExportRequestStatus.PROCESSING)
      ? ExportRequestStatus.PROCESSING
      : false;
    this.valueLocal.status[2] = this.value.status?.includes(ExportRequestStatus.PROCESSED)
      ? ExportRequestStatus.PROCESSED
      : false;
    this.valueLocal.status[3] = this.value.status?.includes(ExportRequestStatus.ERROR)
      ? ExportRequestStatus.ERROR
      : false;
  }

  optionsFormatExport: SelectType[] = [
    {
      text: "CSV",
      value: "CSV",
    },
    {
      text: "XLSX",
      value: "XLSX",
    },
  ];

  monthsToSearchBack = 6;
  minDateStart = this.subtractMonths(new Date(), this.monthsToSearchBack);
  maxDaysToSearch = 31;
  timeStart = "00:00";
  timeEnd = "23:59";

  language = i18n.locale;

  get endSelectorDateEnd(): Date {
    const maxDate = this.addDaysToDate(this.valueLocal.dateStart, this.maxDaysToSearch);
    if (this.intervalExceedNumberOfDays(this.valueLocal.dateStart, this.valueLocal.dateEnd, this.maxDaysToSearch)) {
      this.valueLocal.dateEnd = new Date(maxDate.getTime());
      this.setTime(this.timeStart, this.timeEnd);
    }
    return new Date(maxDate.getTime());
  }
  isIntervalDateValid(): boolean {
    return this.isIntervalValid(this.valueLocal.dateStart, this.valueLocal.dateEnd);
  }
  resetTime(): void {
    this.setTime("00:00", "23:59");
  }
  changeDateStart(): void {
    this.setTime(this.timeStart, this.timeEnd);
    if (!this.isIntervalDateValid()) {
      this.valueLocal.dateEnd = new Date(this.valueLocal.dateStart.getTime());
      this.resetTime();
    }
  }
  changeDateEnd(): void {
    this.setTime(this.timeStart, this.timeEnd);
    if (!this.isIntervalDateValid()) {
      this.valueLocal.dateStart = new Date(this.valueLocal.dateEnd.getTime());
      this.resetTime();
    }
  }
  changeTimeStart(newTimeStart: string): void {
    this.setTime(newTimeStart, this.timeEnd);
    if (!this.isIntervalDateValid()) {
      this.changeTimeEnd(newTimeStart);
    }
  }
  changeTimeEnd(newTimeEnd: string): void {
    this.setTime(this.timeStart, newTimeEnd);
    if (!this.isIntervalDateValid()) {
      this.changeTimeStart(newTimeEnd);
    }
  }
  setTimeFromString(time: string, date: Date, isForEnd = false): void {
    if (time && date) {
      const timeLocal = time.split(":");
      date.setHours(parseInt(timeLocal[0]));
      date.setMinutes(parseInt(timeLocal[1]));
      date.setSeconds(isForEnd ? 59 : 0);
    }
  }
  completeTwoDigitsWithZero(value: string): string {
    const timeLocal = value.split(":");
    return ("0" + timeLocal[0]).slice(-2) + ":" + ("0" + timeLocal[1]).slice(-2);
  }
  setTime(timeStart: string, timeEnd: string): void {
    if (this.valueLocal.dateStart) this.setTimeFromString(timeStart, this.valueLocal.dateStart);
    this.timeStart = this.completeTwoDigitsWithZero(timeStart);
    if (this.valueLocal.dateEnd) this.setTimeFromString(timeEnd, this.valueLocal.dateEnd, true);
    this.timeEnd = this.completeTwoDigitsWithZero(timeEnd);
  }
  setTodayDate(): void {
    this.valueLocal.dateStart = this.getTodayDate();
    this.valueLocal.dateEnd = this.getTodayDate();
    this.resetTime();
  }
  setYesterdayDate(): void {
    this.valueLocal.dateStart = this.getYesterdayDate();
    this.valueLocal.dateEnd = this.getYesterdayDate();
    this.resetTime();
  }
  setThisWeekDate(): void {
    const dates = this.getThisWeekDates();
    this.valueLocal.dateStart = dates.start;
    this.valueLocal.dateEnd = dates.end;
    this.resetTime();
  }
  setThisMonthDate(): void {
    const dates = this.getThisMonthDates();
    this.valueLocal.dateStart = dates.start;
    this.valueLocal.dateEnd = dates.end;
    this.resetTime();
  }

  get textButtonAdvancedSearch(): string {
    const textButton = `${this.$t("exportRequest.filter.advancedSearch")} (${this.numFiltersAdvancedSearch})`;

    this.isActiveButtonAdvancedSearch = !!this.numFiltersAdvancedSearch;

    return textButton;
  }

  get numFiltersAdvancedSearch(): number {
    let numFilter = 0;
    if (this.valueLocal.filename) {
      numFilter++;
    }
    return numFilter;
  }

  search(): void {
    const newValue = { ...this.value };

    newValue.requestedAtStart = this.valueLocal.searchBy === REQUESTED_AT ? this.valueLocal.dateStart : undefined;
    newValue.requestedAtEnd = this.valueLocal.searchBy === REQUESTED_AT ? this.valueLocal.dateEnd : undefined;
    newValue.processedAtStart = this.valueLocal.searchBy === PROCESSED_AT ? this.valueLocal.dateStart : undefined;
    newValue.processedAtEnd = this.valueLocal.searchBy === PROCESSED_AT ? this.valueLocal.dateEnd : undefined;

    newValue.status = this.valueLocal.status.filter((s) => s) as string[];
    newValue.format = this.valueLocal.format;

    newValue.filename = this.valueLocal.filename;

    this.$emit("input", newValue);
    this.$emit("change", newValue);
  }

  onChangeAdvancedSearch() {
    this.showModalAdvancedSearch = false;
    this.search();
  }
}
