import { IGettersMock } from "@/store";

export const mockGetters = (): IGettersMock => ({
  // Root
  getLocale: jest.fn(),
  getUrl: jest.fn(),
  getIsSignedIn: jest.fn(),

  // User
  getLoggedUser: jest.fn(),

  // Country
  getCountries: jest.fn(),

  // Transaction Fields
  getTransactionFields: jest.fn(),
  getTransactionEarlyValueFields: jest.fn(),
  getTransactionFieldGroups: jest.fn(),
  getTransactionsSearchConfig: jest.fn(),

  getOrders: jest.fn(),
  getOrdersSupplies: jest.fn(),
  getAssistanceFailures: jest.fn(),
  getServicePointsOrders: jest.fn(),
  getCashTodayServicePointsOrders: jest.fn(),
  getCenters: jest.fn(),
  getEntities: jest.fn(),
  getStatuses: jest.fn(),
  getCommodities: jest.fn(),
  getCountriesOrders: jest.fn(),
});
