import { ExportRequest } from "@/entities";
import { BvTableField } from "bootstrap-vue";

export interface IField extends BvTableField {
  visible: boolean;
  aux: boolean;
  key: string;
  code?: string;
}

export interface FilterExportRequestsPageProps {
  searchBy: string;
  dateStart: Date;
  dateEnd: Date;
  filename: string;
  format: string;
  status: (string | boolean)[];
}

export interface ExportRequestsFilters {
  status?: string[];
  filename?: string;
  format?: string;
  type?: string[];
  requestedAtStart?: Date;
  requestedAtEnd?: Date;
  processedAtStart?: Date;
  processedAtEnd?: Date;
}

export const REQUESTED_AT = "requestedAt";
export const PROCESSED_AT = "processedAt";

export interface ExportRequestItem extends Partial<ExportRequest> {}
