
import { Component, Vue, Watch } from "vue-property-decorator";
import { i18n } from "@/i18n";
import DateTimeMixin from "@/mixins/datetime";
import { Instant, LocalDateTime } from "@js-joda/core";
import {
  EEarlyValueNotificationType,
  ETransactionOrigin,
  ETransactionTypeList,
  LogicCashToday,
  ServicePoint,
  Transaction,
  TransactionSearchConfig,
  UserPreferences,
} from "@/entities";
import {
  PButton,
  PPersonalizeTable,
  PSidebar,
  PTab,
  PTable,
  PTableType,
  PTabs,
  ButtonBackToDashboard,
  FieldPTable,
  RawFieldPTable,
  FieldPTableType,
  PFormSelect,
} from "@/common/components";
import { FilterOperator } from "@/services/backend/operators";
import DetailTransaction from "./DetailTransaction.vue";
import FilterTransactionsPage from "./FilterTransactionsPage.vue";
import { FilterTransations, TransactionItem } from "./transactionsPage.type";
import { Getter } from "vuex-class";
import { OrderByFields } from "@/services";
import { mergeArrayUniqueValues } from "@/common/utils";
import VueMethods from "@/vue-methods";

@Component({
  name: "transactions-page",
  mixins: [DateTimeMixin, VueMethods],
  components: {
    FilterTransactionsPage,
    PTable,
    PTabs,
    PTab,
    PSidebar,
    PPersonalizeTable,
    PButton,
    DetailTransaction,
    ButtonBackToDashboard,
    PFormSelect,
  },
})
/* eslint-disable @typescript-eslint/no-explicit-any */
export default class TransactionsPage extends Vue {
  @Getter("getTransactionFields") getTransactionFields!: FieldPTable[];
  @Getter("getTransactionEarlyValueFields") getTransactionEarlyValueFields!: FieldPTable[];
  @Getter("getTransactionFieldGroups") getTransactionFieldGroups!: string[];
  @Getter("getTransactionsSearchConfig") getTransactionsSearchConfig!: TransactionSearchConfig;
  language = i18n.locale;
  servicePointId = "";
  servicePoints: ServicePoint[] | undefined = [];
  @Watch("servicePoints", { deep: true, immediate: true })
  onChangeServicePoints(): void {
    this.updateTransactionsFieldsPTable();
  }
  logicCashTodayId = "";
  logicCashTodays: LogicCashToday[] | undefined = [];
  @Watch("logicCashTodays", { deep: true, immediate: true })
  onChangeLogicCashTodays(): void {
    this.updateTransactionsFieldsPTable();
  }
  transactions: Transaction[] = [];
  transactionItems: TransactionItem[] = [];
  iTranslationsTable: any = { empty: this.$t("transactions.haventSearch") };
  showFilter = true;
  showSideBar = false;
  title = this.$t("transactions.detailsTitle");
  showPersonalize = false;
  showDetail = false;
  widthSidebar = "";
  pageSize = 20;
  selectedTransaction: TransactionItem | Transaction | null = null;
  eTransactionTypes = ETransactionTypeList;
  filtersServerParams: any[] = [];
  filters: FilterTransations = {
    dateStart: new Date(),
    dateEnd: new Date(),
    transactionType: [
      this.eTransactionTypes.SHIPOUT,
      this.eTransactionTypes.V_SHIPOUT,
      this.eTransactionTypes.CASHIN,
      this.eTransactionTypes.CASHOUT,
      this.eTransactionTypes.SHIPIN,
    ],
    entityName: "",
    userTransaction: "",
    creditDateStart: null,
    creditDateEnd: null,
    isCheckAccreditation: false,
    entityNameAccrediting: "",
    smartDevices: [],
  };
  @Watch("filters", { deep: true, immediate: true })
  onChangeFilters(): void {
    this.updateTransactionsFieldsPTable();
  }
  paginationDefault = {
    pageSize: this.pageSize,
    pageSizeDefault: this.pageSize,
    totalElements: 0,
    currentPage: 1,
    limit: 300,
  };
  orderDefault = {
    orderFields: [{ field: "transactionDate", direction: "ASC" }],
    groupedFields: [],
  };
  transactionsCustomerFields: FieldPTable[] = [];
  extraDataFields: FieldPTable[] = [];
  paramsFilterTransactions: PTableType = {
    fields: [],
    defaultFields: [],
    pagination: this.paginationDefault,
    order: this.orderDefault,
    groupFields: [],
  };
  @Watch("paramsFilterTransactions.pagination", { deep: true })
  onChangeParamsFilterTransactions(): void {
    this.saveUserPreferences();
  }
  userPreferences: UserPreferences = {} as UserPreferences;

  selectTypeExport = "";
  optionsTypeExport = [
    {
      text: this.$t("transactions.exports.csv"),
      value: "CSV",
    },
    {
      text: this.$t("transactions.exports.xls"),
      value: "XLS",
    },
  ];
  typeExportTranslation = this.$t("transactions.export");

  created(): void {
    this.logicCashTodayId = this.$route.params.logicCashTodayId;
    this.servicePointId = this.$route.params.servicePointId;
    Promise.all([
      // fetch data
      // this.$services.logicCashTodays.fetchLogicCashTodays(),
      this.$services.servicePoints.fetchServicePoints(),
      this.$services.userPreferences.fetchUserPreferences(),
    ]).then((resp) => {
      // save data
      // this.logicCashTodays = resp[0].logicCashTodays;
      this.servicePoints = resp[0].servicePoints;
      this.userPreferences = resp[1];
      this.paramsFilterTransactions.defaultFields = this.getTransactionFields;
      this.paramsFilterTransactions.groupFields = this.getTransactionFieldGroups;
      // manipulate data
      this.setUserPreferences();
    });
  }
  setUserPreferences(): void {
    // default fields takes precedence
    this.userPreferences.transactionsPage.fields.map((rawField: any) => {
      const field = this.paramsFilterTransactions.defaultFields.find((fi: any) => fi.key == rawField.key);
      if (field) this.transactionsCustomerFields.push(FieldPTable.createAndMerge(field, rawField));
    });
    // pagination
    if (this.userPreferences.transactionsPage.pagination.pageSize) {
      this.paginationDefault.pageSize = this.userPreferences.transactionsPage.pagination.pageSize;
    }
  }
  saveUserPreferences(): void {
    this.setUserPreferences();
    if (!this.userPreferences || !this.userPreferences.transactionsPage) return;
    // merge with filter
    this.userPreferences.transactionsPage.fields = this.paramsFilterTransactions.fields.map(
      (field) => new RawFieldPTable(field)
    );
    this.userPreferences.transactionsPage.pagination = { pageSize: this.paramsFilterTransactions.pagination.pageSize };
    // save on backend
    this.$services.userPreferences.saveUserPreferences(this.userPreferences);
  }
  downloadFileExport(): void {
    if (!this.selectTypeExport || this.selectTypeExport === "") return;
    const filters = this.filtersServerParams;
    const orderByFields: OrderByFields[] = [];
    this.paramsFilterTransactions.order.orderFields.map((orderField: any) => {
      orderByFields.push(Object.assign({}, { field: orderField.field, direction: orderField.direction }));
    });
    this.$services.transactions
      .fetchTransactionsFileExport(
        orderByFields,
        filters,
        this.fieldsToExport,
        this.translationsExport,
        this.selectTypeExport
      )
      .then((file: File) => {
        const aElement = document.createElement("a");
        const url = URL.createObjectURL(file);
        aElement.href = url;
        aElement.download = file.name;
        aElement.click();
        window.URL.revokeObjectURL(url);
      });
  }

  get translationsExport(): { field: string; translation: { [key: string]: string } }[] {
    const translations: { field: string; translation: { [key: string]: string } }[] = [];
    const transactionTypes: { [key: string]: string } = {};
    Object.keys(ETransactionTypeList).map(
      (key: any) => (transactionTypes[key] = this.$tc("transactions.transactionType." + key))
    );
    translations.push({ field: "transactionType", translation: transactionTypes });

    const transactionOrigins: { [key: string]: string } = {};
    Object.keys(ETransactionOrigin).map(
      (key: any) => (transactionOrigins[key] = this.$tc("transactions.transactionOrigin." + key))
    );
    translations.push({ field: "origin", translation: transactionOrigins });

    const transactionEarlyValueAccreditationType: { [key: string]: string } = {};
    translations.push({ field: "amount.accreditation.type", translation: transactionEarlyValueAccreditationType });

    const transactionEarlyValueNotificationType: { [key: string]: string } = {};
    transactionEarlyValueNotificationType[EEarlyValueNotificationType.NOT] = this.$tc("common.yes");
    transactionEarlyValueNotificationType[EEarlyValueNotificationType.NO_NOT] = this.$tc("common.no");
    translations.push({ field: "amount.notification.type", translation: transactionEarlyValueNotificationType });

    return translations;
  }

  get fieldsToExport(): { field: string; label: string }[] {
    const fields: { field: string; label: string }[] = [];

    const excludedFieldTypes = [FieldPTableType.HIDDEN, FieldPTableType.ACTION];
    this.paramsFilterTransactions.fields.forEach((field: FieldPTable) => {
      if (field.show && !excludedFieldTypes.includes(field.type))
        fields.push({ field: field.key, label: field.label ?? "" });
    });

    return fields;
  }

  updateTransactionsFieldsPTable(): void {
    if (this.areAnyExtraDataField()) {
      this.paramsFilterTransactions.defaultFields = mergeArrayUniqueValues(
        this.paramsFilterTransactions.defaultFields,
        this.extraDataFields
      );
    }
    if (this.paramsFilterTransactions.fields.length > 0) {
      this.paramsFilterTransactions.defaultFields = mergeArrayUniqueValues(
        this.paramsFilterTransactions.defaultFields,
        this.getTransactionEarlyValueFields
      );
      const userFields = this.updateFieldsFromRawFields(
        this.paramsFilterTransactions.defaultFields,
        this.userPreferences?.transactionsPage?.fields
      );
      this.paramsFilterTransactions.fields = this.updateFieldsFromRawFields(
        userFields,
        this.paramsFilterTransactions.fields
      );
    } else {
      this.paramsFilterTransactions.fields = this.updateFieldsFromRawFields(
        this.paramsFilterTransactions.defaultFields,
        this.transactionsCustomerFields
      );
    }
  }
  areAnyExtraDataField(): boolean {
    return this.extraDataFields.length > 0;
  }
  getExtraDataFields(extraData: any): FieldPTable[] {
    const extraDataFields: FieldPTable[] = [];
    for (const key of Object.keys(extraData)) {
      const field = new FieldPTable({
        key: "extraData." + key,
        label: key,
        width: "110px",
        groupField: "" + i18n.t("transactions.field.groupFieldExtradaData"),
        showFilterField: false,
        show: false,
      });
      extraDataFields.push(field);
    }
    return extraDataFields;
  }
  updateFieldsFromRawFields(fields: FieldPTable[], rawFields: RawFieldPTable[]): FieldPTable[] {
    const returnTemp: FieldPTable[] = [];
    const fieldsNotSaved = fields.filter((fi) => !rawFields.find((rawField) => rawField.key == fi.key));
    rawFields.map((rawField) => {
      const fieldBase = fields.find((fi) => fi.key == rawField.key);
      const fieldCustomer = new FieldPTable(rawField);
      if (fieldBase) returnTemp.push(Object.assign({}, fieldBase, fieldCustomer));
    });
    if (fieldsNotSaved) returnTemp.push(...fieldsNotSaved);
    return returnTemp;
  }
  resetPagination(): void {
    this.paramsFilterTransactions.pagination = this.paginationDefault;
    this.paramsFilterTransactions.order = this.orderDefault;
  }
  searchTransactions(filter: FilterTransations): void {
    const dayInMilliseconds = 1000 * 3600 * 24;
    const transactionSearchLimit = this.getTransactionsSearchConfig.transactionSearchLimit;
    const machineTransactionsPerDayEstimated = this.getTransactionsSearchConfig.machineTransactionsPerDayEstimated;
    const daysSelected = (filter.dateEnd.getTime() - filter.dateStart.getTime()) / dayInMilliseconds;

    const filtersServerParams: any[] = [];

    const hasData = (obj: any) => obj && obj != "";

    const dateStart = LocalDateTime.ofInstant(Instant.parse(filter.dateStart.toISOString()));
    const dateEnd = LocalDateTime.ofInstant(Instant.parse(filter.dateEnd.toISOString()));
    const field = filter.searchBy ? "acreditationDate" : "transactionDate";
    filtersServerParams.push({ field: field, operator: FilterOperator.BETWEEN, value: dateStart + "," + dateEnd });

    const servicePoints: string[] = [];
    this.servicePoints
      ?.filter(hasData)
      .filter((servicePoint) => filter.smartDevices.includes(servicePoint.id))
      .forEach((servicePoint) => servicePoints.push(servicePoint.id));
    if (servicePoints && servicePoints.length > 0)
      filtersServerParams.push({
        field: "servicePointId",
        operator: FilterOperator.IN,
        value: servicePoints.toString(),
      });

    const logicCashTodays: string[] = [];
    this.logicCashTodays
      ?.filter(hasData)
      .filter((logicCashToday) => filter.smartDevices.includes(logicCashToday.id))
      .forEach((logicCashToday) => logicCashTodays.push(logicCashToday.id));
    if (logicCashTodays && logicCashTodays.length > 0)
      filtersServerParams.push({
        field: "logicCashTodayId",
        operator: FilterOperator.IN,
        value: logicCashTodays.toString(),
      });

    const transactionType: string[] = [];
    filter.transactionType.filter(hasData).forEach((tr) => transactionType.push(tr));
    if (transactionType.toString())
      filtersServerParams.push({
        field: "transactionType",
        operator: FilterOperator.IN,
        value: transactionType.toString(),
      });

    if (filter.entityName)
      filtersServerParams.push({
        field: "entity,name",
        operator: FilterOperator.FULL_TEXT_SEARCH,
        value: filter.entityName,
      });
    if (filter.userTransaction)
      filtersServerParams.push({
        field: "tellerName",
        operator: FilterOperator.FULL_TEXT_SEARCH,
        value: filter.userTransaction,
      });

    /** @TODO revisar estos parametros de isEarlyValue */
    if (filter.creditDateStart && filter.creditDateEnd) {
      const creditDateStart = LocalDateTime.ofInstant(Instant.parse(filter.creditDateStart.toISOString()));
      const creditDateEnd = LocalDateTime.ofInstant(Instant.parse(filter.creditDateEnd.toISOString()));
      filtersServerParams.push({
        field: "creditDate",
        operator: FilterOperator.BETWEEN,
        value: creditDateStart + "," + creditDateEnd,
      });
    }
    // siempre debe estar? o solo cuando es true?
    if (filter.isCheckAccreditation)
      filtersServerParams.push({
        field: "isCheckAccreditation",
        operator: FilterOperator.EQUALS,
        value: filter.isCheckAccreditation,
      });
    if (filter.entityNameAccrediting)
      filtersServerParams.push({
        field: "entityNameAccrediting",
        operator: FilterOperator.CONTAINS,
        value: filter.entityNameAccrediting,
      });

    const machinesSelected = servicePoints.length + logicCashTodays.length;

    if (daysSelected * machinesSelected * machineTransactionsPerDayEstimated > transactionSearchLimit) {
      this.$swal({
        title: this.$t("transactions.exportRequest.title"),
        html: this.$t("transactions.exportRequest.message"),
        icon: "question",
        showCancelButton: true,
        showDenyButton: true,
        showCloseButton: true,
        showConfirmButton: true,
        confirmButtonText: this.$t("transactions.exports.xls"),
        denyButtonText: this.$t("transactions.exports.csv"),
        cancelButtonText: this.$t("button.cancel"),
        confirmButtonColor: "#1D6F42",
        denyButtonColor: "#53af46",
      }).then((result) => {
        if (result.isConfirmed || result.isDenied) {
          const requestBody: Record<string, unknown> = {};
          requestBody["filters"] = filtersServerParams;
          requestBody["sortFields"] = [
            {
              field: "transactionDate",
              direction: "ASC",
            },
          ];
          requestBody["fields"] = this.fieldsToExport;
          requestBody["translations"] = this.translationsExport;
          requestBody["format"] = result.isConfirmed ? "XLSX" : "CSV";

          this.$services.exportRequest.saveTransactionsExportRequest(requestBody).then(() => {
            this.makeToast("success", this.$t("transactions.exportRequest.success"));
          });
        }
      });
    } else {
      this.resetPagination();
      this.iTranslationsTable = undefined;
      this.filters = JSON.parse(JSON.stringify(filter));
      this.filtersServerParams = filtersServerParams;

      this.giveMeTransations();
    }
  }
  showSideBarPersonalize(): void {
    this.showPersonalize = true;
    this.widthSidebar = "";
    this.title = this.$t("transactions.personalize");
    this.showSideBar = true;
  }
  changeMainTable(paramsFilterTransactions: PTableType): void {
    this.paramsFilterTransactions.pagination = { ...paramsFilterTransactions.pagination };
    this.giveMeTransations();
  }
  areFiltersSet(): boolean {
    return this.filters.smartDevices && this.filters.smartDevices.length > 0 && this.filters.transactionType.length > 0;
  }
  areTransactions(): boolean {
    return this.transactionItems.length > 0;
  }
  giveMeTransations(): void {
    if (!this.areFiltersSet()) return;
    const limit = this.paramsFilterTransactions.pagination.pageSize;
    const offset =
      (this.paramsFilterTransactions.pagination.currentPage - 1) * this.paramsFilterTransactions.pagination.pageSize;

    const orderByFields: OrderByFields[] = [];
    this.paramsFilterTransactions.order.orderFields.map((orderField: any) => {
      orderByFields.push(Object.assign({}, { field: orderField.field, direction: orderField.direction }));
    });

    const filters = this.filtersServerParams;
    // call backend
    this.$services.transactions.fetchTransactions(limit, offset, orderByFields, filters).then((resp) => {
      this.transactions = [...resp.transactions];
      this.transactionItems = [...resp.transactions];
      let transactionItemsNew: TransactionItem[] = [];
      let allExtraDataCombined = {};

      this.transactionItems.map((transactionItem) => {
        transactionItem.tableKey = transactionItem.id;
        // extraData are dinamyc, put all in a object with extraData by attribute
        this.flatExtraDatas(transactionItem);
        allExtraDataCombined = Object.assign(allExtraDataCombined, transactionItem.extraData);
        // split transaction by iso
        const transactionItemsTemp: TransactionItem[] = [];
        const currencies = this.getTransactionCurrencies(transactionItem);
        currencies.map((currency) => {
          const transactionItemNew = this.createTransactionItemByCurrency(transactionItem, currency);
          if (transactionItemNew.amounts.length > 0) transactionItemsTemp.push(transactionItemNew);
        });
        // remove duplicate props
        transactionItemsNew = transactionItemsNew.concat(
          this.removeDuplicatePropertiesFromTransactionMultipleCurrencies(transactionItemsTemp)
        );
      });

      this.transactionItems = transactionItemsNew;
      this.paramsFilterTransactions.pagination.totalElements = resp.totalResult;
      this.extraDataFields = this.getExtraDataFields(allExtraDataCombined);
      this.updateTransactionsFieldsPTable();
    });
  }
  getMergedExtraDataField(): any {
    const extraData = {};
    this.transactionItems.map((field) => Object.assign(extraData, field.extraData));
    return extraData;
  }
  flatExtraDatas(transactionItem: TransactionItem) {
    const extraData: any = transactionItem.extraDatas?.reduce(
      (acc: any, curr: any) => ((acc[curr["name"].replace(/\s/g, "")] = curr["value"]), acc),
      {}
    );
    transactionItem.extraData = extraData;
  }
  removeDuplicatePropertiesFromTransactionMultipleCurrencies(transactionItems: TransactionItem[]): TransactionItem[] {
    const transactionItemsNew: TransactionItem[] = [];
    transactionItemsNew.push(transactionItems[0]);
    transactionItemsNew[0].barCodes = transactionItems[0].amount.barCodes.join(", ");
    for (const item of transactionItems.slice(1)) {
      const itemNew = Object.assign(
        {},
        {
          tableKey: item.id,
          id: item.id,
          amount: item.amount,
          amounts: item.amounts,
          currency: item.currency,
          currencies: item.currencies,
          denominations: item.denominations,
          barCodes: item?.amount?.barCodes?.join(", ") ?? "",
        }
      );
      transactionItemsNew.push(itemNew);
    }
    return transactionItemsNew;
  }

  createTransactionItemByCurrency(transactionItem: TransactionItem, iso: string) {
    let amounts: any[] = [];
    let amount = {};
    if (transactionItem.amounts) {
      amounts = transactionItem.amounts.filter((prop) => prop.currency == iso);
      amount = amounts.length > 0 ? amounts[0] : {};
    }
    let currencies: any[] = [];
    let currency = {};
    if (transactionItem.currencies) {
      currencies = transactionItem.currencies.filter((prop) => prop.iso == iso);
      currency = currencies.length > 0 ? currencies[0] : {};
    }
    let denominations: any[] = [];
    if (transactionItem.denominations) {
      denominations = transactionItem.denominations.filter((prop) => prop.currency == iso);
    }
    return Object.assign({}, transactionItem, { amount, amounts, currencies, currency, denominations });
  }
  getTransactionCurrencies(transactionItem: TransactionItem): string[] {
    if (transactionItem.currencies) {
      const currencies: string[] = transactionItem.currencies.map((c) => c.iso);
      return Array.from(new Set(currencies));
    }
    return [];
  }
  viewTransation(rowTransaction: TransactionItem): void {
    this.title = this.$t("transactions.detailsTitle");
    // busco una transacción con todos los datos
    const transaction = this.transactions.find(
      (tr) => tr.id == rowTransaction.id && tr.origin == rowTransaction.origin
    );
    this.selectedTransaction = transaction ? transaction : null;
    this.showPersonalize = false;
    this.widthSidebar = "613px";
    this.showSideBar = true;
    this.showDetail = true;
  }
  changePersonalize(): void {
    this.showPersonalize = false;
    this.showSideBar = false;
    this.showDetail = false;
    this.saveUserPreferences();
  }
}
