
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { SelectType } from "./pFormSelect.types";
import PDropDownContainer from "../PDropDownContainer/PDropDownContainer.vue";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";
import { PFormSelectTranslations, translations } from "./pFormSelectLanguages";

@Component({
  components: {
    PDropDownContainer,
    PIcon,
  },
})
/* eslint-disable @typescript-eslint/no-explicit-any */
export class PFormSelect extends Vue {
  @Prop({ required: true }) value!: any;
  @Watch("value", { immediate: true })
  onChangeValue(): void {
    if (this.value) {
      this.selectedItemValue = Object.assign(this.value);
    } else {
      this.selectedItemValue = "";
    }
  }
  @Prop({ required: true }) options!: SelectType[];
  @Prop({ required: false, default: "" }) placeholder!: string;
  @Prop({ required: false, default: "es" }) language!: string;
  @Prop({ required: false }) translation!: PFormSelectTranslations;
  @Prop({ required: false, default: "primary" }) variant!: string;
  @Prop({ required: false, default: "" }) icon!: string;
  @Prop({ required: false, default: "" }) iType!: string;
  @Prop({ required: false, default: 16 }) iSize!: number;
  @Prop({ required: false, default: "#6f6f6f" }) iColor!: string;
  @Prop({ required: false }) dropDownPlacement!: string;
  @Prop({ required: false, default: false }) staySelectItem!: boolean;
  @Prop({ required: false, default: false }) disabled!: boolean;

  selectedItemValue = "";
  pussOption = false;
  translator: PFormSelectTranslations = translations[0].translations;

  clearSelection(): void {
    this.selectItem("");
  }
  created(): void {
    const language = this.language.split("-").shift();
    const translation = translations.find((tran) => tran.language === language)?.translations;
    if (translation) {
      this.translator = translation;
    }
    if (this.translation) {
      this.translator = Object.assign({}, this.translator, this.translation);
    }
  }

  get variantCop(): string {
    let returnTemp: string = "inputSelect_PFormSelect_" + this.variant;
    if (this.variant === "button" && this.selectedItemValue != "") {
      returnTemp = returnTemp + " borderSelected_PFormSelect";
    }
    return returnTemp;
  }
  get variantExpandCop(): string {
    return "expand_moreSelect_PFormSelect_" + this.variant;
  }
  get cursor(): string {
    return this.disabled ? "containerSelect_PFormSelect disabled" : "containerSelect_PFormSelect";
  }
  get selectedText(): string {
    const optionFind = this.options.find((opt) => opt.value == this.selectedItemValue);
    if (optionFind) {
      return optionFind.text;
    } else {
      return this.localPlaceHolder;
    }
  }
  get localPlaceHolder(): string {
    return !this.placeholder && this.translator?.textSelectAnOption
      ? this.translator?.textSelectAnOption
      : this.placeholder;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  selectItem(itemValue: any): void {
    if (this.selectedItemValue && this.selectedItemValue == this.value && this.selectedItemValue == itemValue) {
      this.$emit("input", "");
      this.$emit("change", "");
    } else {
      this.$emit("input", itemValue);
      this.$emit("change", itemValue);
    }
    this.pussOption = false;
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  giveMeClass(itemValue: any): string {
    if (this.selectedItemValue == itemValue && this.selectedItemValue == this.value) {
      return "itemSelectSelected_PFormSelect";
    } else {
      return "itemSelect_PFormSelect";
    }
  }
  handleContainerClick(): void {
    if (this.staySelectItem && this.selectedItemValue) {
      this.$emit("input", this.selectedItemValue);
    } else {
      this.pussOption = !this.pussOption;
    }
  }
}
export default PFormSelect;
