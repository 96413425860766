import { RawExportRequest, RawExportRequests } from "@/entities";

export class ExportRequest {
  readonly id: string;
  readonly requestedAt?: Date;
  readonly processedAt?: Date;
  readonly expiresAt?: Date;
  readonly status: string;
  readonly type: string;
  readonly filename?: string;
  readonly format?: string;

  constructor(data: RawExportRequest) {
    this.id = data.id;
    this.requestedAt = new Date(data.requestedAt);
    this.processedAt = data.processedAt ? new Date(data.processedAt) : undefined;
    this.expiresAt = data.expiresAt ? new Date(data.expiresAt) : undefined;
    this.status = data.status;
    this.type = data.type;
    this.filename = data.filename;
    this.format = data.format;
  }
}

export class ExportRequests {
  readonly exportRequests: ExportRequest[];
  readonly totalResults: number;
  constructor(data: RawExportRequests) {
    this.totalResults = data.totalResults;
    const exportRequests: ExportRequest[] = [];
    data.results?.forEach((rawData: RawExportRequest) => exportRequests.push(new ExportRequest(rawData)));
    this.exportRequests = exportRequests;
  }
}
