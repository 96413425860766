import { RawSearchConfig } from "@/entities";

export class TransactionSearchConfig {
  readonly machineTransactionsPerDayEstimated: number;
  readonly transactionSearchLimit: number;

  constructor(data: RawSearchConfig) {
    this.machineTransactionsPerDayEstimated = data.machineTransactionsPerDayEstimated;
    this.transactionSearchLimit = data.transactionSearchLimit;
  }
}
