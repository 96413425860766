export interface RawExportRequest {
  readonly id: string;
  readonly filename?: string;
  readonly status: string;
  readonly type: string;
  readonly requestedAt: string;
  readonly processedAt?: string;
  readonly expiresAt?: string;
  readonly format?: string;
}

export type RawExportRequests = {
  readonly results: RawExportRequest[];
  readonly totalResults: number;
};

export enum ExportRequestStatus {
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  PROCESSED = "PROCESSED",
  ERROR = "ERROR",
}
