import Vue from "vue";
import Router from "vue-router";
import { IStore } from "@/store";
import CallbackPage from "@/pages/CallbackPage.vue";
import { DefaultLayout } from "@/common/components";
import ErrorPage from "@/pages/ErrorPage.vue";
import NotFoundErrorPage from "@/pages/NotFoundErrorPage.vue";
import DashboardPage from "@/pages/dashboard/DashboardPage.vue";
import ServicePointDetailPage from "@/pages/service-point-detail/ServicePointDetailPage.vue";
import LogicCashTodayDetailPage from "@/pages/logic-cash-today-detail/LogicCashTodayDetailPage.vue";
import TransactionsPage from "@/pages/transactions/TransactionsPage.vue";
import TransationsAlertPage from "@/pages/transationsAlert/TransationsAlertPage.vue";
import HomeViewPage from "@/orders/views/HomeViewPage.vue";
import DayViewPage from "@/orders/views/DayViewPage.vue";
import BaseOrderPage from "@/orders/views/BaseOrderPage.vue";
import MonthViewPage from "@/orders/views/MonthViewPage.vue";
import EmployeesViewPage from "@/orders/views/EmployeesViewPage.vue";
import ExportRequestsPage from "@/pages/export-requests/ExportRequestsPage.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "root",
      component: DefaultLayout,
      redirect: "/dashboard",
      children: [
        {
          path: "dashboard",
          name: "dashboard",
          component: DashboardPage,
          meta: {
            resource: "DashboardPage",
          },
        },
        {
          path: "dashboardBalances",
          name: "dashboardBalances",
          component: DashboardPage,
          meta: {
            resource: "DashboardPage",
            endpointVersion: "v2",
          },
        },
        {
          path: "service-point/:balanceId",
          component: ServicePointDetailPage,
          meta: {
            resource: "ServicePointDetailPage",
          },
        },
        {
          path: "logic-cash-today/:balanceId",
          component: LogicCashTodayDetailPage,
          meta: {
            resource: "LogicCashTodayDetailPage",
          },
        },
        {
          path: "transactions",
          name: "transactions",
          component: TransactionsPage,
          meta: {
            resource: "TransactionsPage",
          },
        },
        {
          path: "transactions/logic-cash-today/:logicCashTodayId",
          name: "logic-cash-today-transactions",
          component: TransactionsPage,
          meta: {
            resource: "TransactionsPage",
          },
        },
        {
          path: "transactions/service-point/:servicePointId",
          name: "service-point-transactions",
          component: TransactionsPage,
          meta: {
            resource: "TransactionsPage",
          },
        },
        {
          path: "transactions-alerts",
          name: "transactions-alerts",
          component: TransationsAlertPage,
          meta: {
            resource: "TransationsAlertPage",
          },
        },
        {
          path: "orders",
          name: "orders",
          component: BaseOrderPage,
          meta: {
            resource: "BaseOrderPage",
          },
          children: [
            {
              path: "",
              name: "ordersWeek",
              component: HomeViewPage,
              meta: {
                resource: "HomeViewPage",
              },
            },
            {
              path: "ordersDay",
              name: "ordersDay",
              component: DayViewPage,
              meta: {
                resource: "DayViewPage",
              },
            },
            {
              path: "ordersMonth",
              name: "ordersMonth",
              component: MonthViewPage,
              meta: {
                resource: "MonthViewPage",
              },
            },
            {
              path: "ordersEmployees",
              name: "ordersEmployees",
              component: EmployeesViewPage,
              meta: {
                resource: "EmployeesViewPage",
              },
            },
          ],
        },
        {
          path: "exportRequests",
          name: "exportRequests",
          component: ExportRequestsPage,
          meta: {
            resource: "ExportRequestsPage",
          },
        },
        {
          path: "callback",
          name: "callback",
          component: CallbackPage,
          meta: {
            anonymous: true,
          },
        },
        {
          path: "404",
          name: "404",
          component: NotFoundErrorPage,
          meta: {
            anonymous: true,
          },
        },
        {
          path: "error",
          name: "error",
          component: ErrorPage,
          meta: {
            anonymous: true,
          },
        },
      ],
    },
    { path: "*", redirect: "/404" },
  ],
});

export const useRouter = (store: IStore): Router => {
  router.beforeEach(async (to, _from, next) => {
    if (to.matched.some((record) => record.meta.anonymous)) {
      next();
    } else {
      await store.dispatch("setUrl", `${window.origin}${to.fullPath}`);
      next();
    }
  });

  return router;
};
