
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { PButton, PCol, PFormInputText, PRow } from "@/common/components";
import { i18n } from "@/i18n";
import { FilterExportRequestsPageProps } from "@/pages/export-requests/exportRequestsPage.type";

@Component({
  components: {
    PRow,
    PCol,
    PButton,
    PFormInputText,
  },
})
export class FilterAdvancedSearchExportRequestsPage extends Vue {
  @Prop({ required: true }) value!: FilterExportRequestsPageProps;
  @Watch("value", { immediate: true })
  onChangeValue(): void {
    this.valueLocal = { ...this.value };
  }

  language = i18n.locale;
  valueLocal = { ...this.value };

  clearAdvancedSearch(): void {
    this.valueLocal.filename = "";

    this.$emit("input", this.valueLocal);
    this.$emit("change", this.valueLocal);
  }

  applyAdvancedSearch(): void {
    this.$emit("input", this.valueLocal);
    this.$emit("change", this.valueLocal);
  }
}
export default FilterAdvancedSearchExportRequestsPage;
