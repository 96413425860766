import {
  BACKEND_BASE_URL,
  BACKEND_BASE_URL_READS,
  ExportRequestService,
  ExportRequestsParams,
  OrderByFields,
} from "@/services";
import { AxiosRequestConfig } from "axios";
import { ExportRequests, RawExportRequests } from "@/entities";
import Vue from "vue";

export class ExportRequestServiceImpl implements ExportRequestService {
  async saveTransactionsExportRequest(map: Record<string, unknown>): Promise<void> {
    const url = `${BACKEND_BASE_URL}/api/v1/transactions/export`;
    await Vue.$axios.post(url, map);
  }

  async downloadExport(exportRequestId: string): Promise<File> {
    const postConfig = {
      headers: {
        Accept: "*/*",
        "X-Requested-With": "XMLHttpRequest",
      },
      responseType: "blob",
    } as AxiosRequestConfig;

    const response = await Vue.$axios.get<Blob>(
      `${BACKEND_BASE_URL_READS}/api/v1/export-request/download/${exportRequestId}`,
      postConfig
    );
    const contentType = response.headers["content-type"];
    const filename = response.headers["content-disposition"].split("filename=")[1].split(";")[0];
    const blob = new Blob([response.data as BlobPart], {
      type: contentType,
    });

    return new File([blob], filename);
  }

  async fetchExportRequests(
    filters: ExportRequestsParams,
    sortFields: OrderByFields[],
    limit: number,
    offset: number
  ): Promise<ExportRequests> {
    const url = `${BACKEND_BASE_URL_READS}/api/v1/export-request/page`;
    const body = { limit, offset, sortFields, filters };
    const { data } = await Vue.$axios.post<RawExportRequests>(url, body);

    return new ExportRequests(data);
  }
}
