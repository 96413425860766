import { IMutationsMock } from "./mutations.types";

export const mockMutations = (): IMutationsMock => ({
  // Root
  SET_LOCALE: jest.fn(),
  SET_URL: jest.fn(),
  SET_IS_SIGNED_IN: jest.fn(),
  SET_LOGGED_USER: jest.fn(),

  // Country
  SET_COUNTRIES: jest.fn(),

  SET_ORDERS: jest.fn(),
  SET_SERVICE_POINT_ORDERS: jest.fn(),
  SET_STATUSES: jest.fn(),
  SET_COMMODITIES: jest.fn(),

  SET_TRANSACTIONS_SEARCH_CONFIG: jest.fn(),
});
